import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DictionaryService} from '@amlCore/services';
import {ClientInfo, DictType} from '../../../../../models/dossier';
import {Unsubscribable} from 'rxjs';
import {AlertPanelService, ITableEditorItem, ITableItem} from '@amlCore/components';
import {DossierService} from '../../../../../services';
import {DossierBaseModel} from '../../dossierBaseModel';
import {DossierBaseSubComponent} from '../dossierBaseSub.component';
import {ClientTypeEnum} from '@amlCore/enums';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-sved-client-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './clientInfoDossier.component.html'
})
export class ClientInfoDossierComponent extends DossierBaseSubComponent implements ITableItem, OnInit, OnDestroy, AfterViewChecked {
  public static rezidentValues: string[] = ['rezidentRf', 'rezidentUsa', 'rezidentFS'];
  @Input() titlePanel = 'Сведения о клиенте';
  @Input() showTitle = true;
  @Output() clientTypeChange = new EventEmitter();
  isTableItem = false;
  clientTypeEnum = ClientTypeEnum;
  clientTypeList: Array<DictType> = [];
  clientTrueList: DictType[] = [];
  filialList: DictType[] = [];
  clientTypeValueChanges: Unsubscribable;
  isClientTypeShow = true;
  /**
   * Соответствие по clientType какую часть оставлять
   * @key - значение clientType
   * @value - имя части
   */
  clientInfoTypePanel = {
    1: 'svedUL',
    2: 'svedFL',
    3: 'svedIP',
    4: 'svedFLCP',
    5: 'svedInbUL',
  };

  constructor(protected dossierService: DossierService,
              protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService,
              private _changeDetectorRef: ChangeDetectorRef
              ) {
    super(dossierService, dictSrv, alertPanelService);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Описание формы
   */
  getFormDescription(): FormGroup {
    return this.dossierBaseModel.getClientInfo(this.typePage);
  }

  /**
   * Инициализация FormGroup
   */
  initForm(): void {
    if (!this.isTableItem) {
      this.formGroupApp = this.getFormDescription();
    }
  }

  ngOnInit(): void {
    this.initClientType();
    this.initTypePage();
    this.filialList = this.dictSrv.getStaticData("filialList");

    // Скрываем/отображаем условную часть
    setTimeout(() => {
      const clientType = this.f('clientType').value;
      this.setActiveClientInfo(clientType);
      this.clientTypeChange.emit(clientType);
      this._setRezidentRf(clientType);
      this.clientTypeValueChanges = this.f('clientType')
          .valueChanges
          .pipe(debounceTime(100))
          .subscribe((clientTypeToken: string) => {
              this.setActiveClientInfo(clientTypeToken);
              this.clientTypeChange.emit(clientTypeToken);
              this._setRezidentRf(clientTypeToken);
          });
      this.addUnsubscribable(this.clientTypeValueChanges);
    });
    if (this.isTableItem) {
      this.focusObject = this.generateFocusObject(new ClientInfo());
    }
    setTimeout(() => {
      this.selectChange();
    }, 0);
  }

  private _setRezidentRf(clientTypeValue: string): void {
    if (this._isEdit() && ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR === clientTypeValue) {
      const value = ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR === clientTypeValue;
      this.f('rezidentRf').setValue(value);
    }
    if (!this._isEdit()) {
      const value = ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR === clientTypeValue;
      this.f('rezidentRf').setValue(value);
    }
    this.f('rezidentRf').updateValueAndValidity({onlySelf: false});
  }

  private _isEdit(): boolean {
    return !!this.dossierService.getDossierId();
  }

  /**
   * Инициализация доступных лиц для выбора по типу вкалдки
   */
  initClientType(): void {
      // получаем список тип клиента по типу вкладки
    this.dictSrv.loadTypeClient().then(result => {
      this.clientTypeList = result.filter(item => {
        if(item.code == 0) return false;
        if(this.typePage === 'svedAU')
          return [ClientTypeEnum.LEGAL_ENTITY, ClientTypeEnum.INDIVIDUAL_PERSON].includes(item.code);
        if(this.typePage === 'svedIOU')
          return [ClientTypeEnum.LEGAL_ENTITY, ClientTypeEnum.INDIVIDUAL_PERSON, ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR].includes(item.code);
        return true;
      });
    });
    this.dictSrv.getRemoteData('clientTrue').then(result => {
      this.clientTrueList = result;
    });
  }

  /**
   * Инициализация typePage - скрываем некоторые части формы
   */
  initTypePage(): void {
    // Для svedBV доступен только фл - автовыбираем тип и скриываем поле
    if (this.typePage === 'svedBV') {
      this.formGroupApp.patchValue({clientType: '2'});
      this.isClientTypeShow = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribable();
  }

  /**
   * Установить активную часть ClientInfo
   * @param type - код части
   */
  setActiveClientInfo(type): void {
    setTimeout(() => {
      if (!this.isReadOnly) {
        this.f('svedUL').disable();
        this.f('svedFL').disable();
        this.f('svedIP').disable();
        this.f('svedFLCP').disable();
        this.f('svedInbUL').disable();
        const elem = this.clientInfoTypePanel[type];
        if (elem !== undefined) {
          this.f(elem).enable();
        }
        this.f('filial')[ClientTypeEnum.LEGAL_ENTITY === type ?"enable":"disable"]();
      }
    });
  }

  getTitle(): string {
    return this.titlePanel;
  }

  /**
   * Инициализируем компонент как элемент формы просмотра таблицы
   * @param param.form - форма для связи
   * @param param.params - дополнительные параметры
   */
  initTableItem(param: ITableEditorItem): FormGroup {
    if (param.params) {
      if (param.params.typePage) {
        this.typePage = param.params.typePage;
      }
      if (param.params.titlePanel) {
        this.titlePanel = param.params.titlePanel;
      }
    } else {
      console.log('Необходимо указать typePage у компонента');
    }
    this.initForm();
    this.isTableItem = true;
    if (param.model) {
      this.formGroupApp.patchValue(param.model);
    }
    this.showTitle = false;
    this.isReadOnly = param.isReadOnly;
    if (this.isReadOnly){
      this.formGroupApp.disable();
    }
    return this.formGroupApp;
  }

  /**
   * Изменение чекбоксов резидентов
   */
  resetRezident(ev: any){
     if(ev.target.checked){
         ClientInfoDossierComponent.rezidentValues
          .filter(item => item !== ev.target.id)
          .forEach(item => {
              this.f(item).patchValue(this.typePage === 'svedClient' ? false : null);
      })
     }
  }

  selectChange(e?: number) {
    this.dossierService.clientTypeEmit.emit(this.f('clientType').value);
  }
}
