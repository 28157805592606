import {DictType} from "./dictType.model";
import {DossierDate, DossierT250, DossierT50} from "./base.model";

export class RegInfoCPType {
  vid: DictType;
  otherVid: DossierT50 = '';
  nameOrg: DossierT250 = '';
  regNum: DossierT50 = '';
  dateReg: DossierDate = '';
  numCertificate: DossierT50 = '';
  innCRO: DossierT50 = '';
  ogrnCRO: DossierT50 = '';
}
