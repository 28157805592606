import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";
import {merge, Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DictType} from "../../../../../models";

@Component({
  selector: 'app-fio-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './fioDossier.component.html'
})
export class FioDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {

  @Input() titlePanel = "";

  private destroy$ = new Subject();
  protected genderList: DictType[] = [];

  constructor(protected dossierService: DossierService, protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit(): void {
    this.genderList = this.getStaticData('gender');
    merge(
      this.f('lastName').valueChanges,
      this.f('firstName').valueChanges,
      this.f('middleName').valueChanges
    ).pipe(takeUntil(this.destroy$)).subscribe(() => {
      const formValue = this.formGroupApp.value;
      this.f('fio').patchValue([formValue.lastName, formValue.firstName, formValue.middleName].filter(Boolean).join(" "));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
