import {ICustomSelectMapper} from "@amlCore/components";

export class CurrencyMapper implements ICustomSelectMapper {
  toModel(origin: any, isDictData: boolean): any {
    if (isDictData) {
      return {
        code: origin.iso_dig,
        name: origin.name
      };
    } else {
      return origin;
    }
  }
}