import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {DossierService} from '../../../../../services';
import {DictType} from '../../../../../models/dossier';
import {Unsubscribable} from 'rxjs';
import {DictionaryService} from '@amlCore/services';
import {DossierBaseModel} from '../../dossierBaseModel';
import {DossierBaseSubComponent} from '../dossierBaseSub.component';
import {AlertPanelService} from '@amlCore/components';
import {FormGroup} from '@angular/forms';
import {pairwise, startWith} from "rxjs/operators";

@Component({
  selector: 'app-document-info-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './documentInfoDossier.component.html'
})
export class DocumentInfoDossierComponent extends DossierBaseSubComponent implements OnInit, OnDestroy {

  @Output() addDoc = new EventEmitter<string>();
  nationalityList: Array<DictType> = [];
  docTypeList: Array<DictType> = [];
  docTypeDict: Array<DictType> = [];
  docTypeValueChanges: Unsubscribable;
  nationalityValueChanges: Unsubscribable;

  constructor(protected readonly dossierService: DossierService,
              protected readonly dictSrv: DictionaryService,
              protected readonly dossierBaseModel: DossierBaseModel,
              protected readonly alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit() {
    Promise.all([
      this.dictSrv.getRemoteData('typecitizenship'),
      this.dictSrv.getRemoteData('documenttypecodes')
    ]).then(([_typecitizenship, _documenttypecodes]) => {
      this.nationalityList = _typecitizenship.filter(item => !['8','0'].includes(item.code));
      this.docTypeDict = _documenttypecodes;

      this.initDocType(this.f('docType').value);
      this.docTypeValueChanges = this.f('docType').valueChanges.pipe(startWith(<string>null), pairwise()).subscribe(([prev, next]: [DictType, DictType]) => {
        if(this.isPassportOfCitizenRF() && prev && prev.code!=next.code){
          this.formGroupApp.get('kodPodr').reset();
        }
        this.initDocType(next);
      });

      this.initNationality(this.f('nationality').value);
      this.nationalityValueChanges = this.f('nationality').valueChanges.pipe(startWith(<string>null), pairwise()).subscribe(([prev, next]: [DictType, DictType]) => {
        if(prev && prev.code!=next.code && [prev.code, next.code].includes('1')){
          this.formGroupApp.get('docType').reset();
        }
        this.initNationality(next);
      });

      this.addUnsubscribable(this.docTypeValueChanges);
      this.addUnsubscribable(this.nationalityValueChanges);
    });
  }

  initDocType(value: DictType) {
    if (!this.formGroupApp.disabled) {
      const docOther = this.f('docOther');
      const dateEnd = this.f('dateEnd');
      setTimeout(() => {
        docOther.disable();
        dateEnd.disable();
      });
      if (this.isDocOtherEnabled(value?.code)) {
        setTimeout(() => {
          docOther.enable();
        });
      }
      if (this.isDateEndEnabled(value?.code)) {
        setTimeout(() => {
          dateEnd.enable();
        });
      }
    }
  }

  isDocOtherEnabled(docTypeCode?: string): boolean{
    return ['19', '99'].includes(docTypeCode || this.f('docType')?.value?.code);
  }

  isDateEndEnabled(docTypeCode?: string): boolean{
    return ['2', '3', '4', '5', '6', '7', '11', '14', '15', '16', '17', '20', '21', '22', '23', '24', '25'].includes(docTypeCode);
  }

  isSeriesRequired (docType: string) {
    return docType === '1';
  }

  isDocNumberRequired (docType: string) {
    return docType && !['8', '19', '99'].includes(docType);
  }

  initNationality(value: DictType) {
    if (!this.formGroupApp.disabled) {
      const migrationCardInfo = this.f('migrationCardInfo');
      const residenceDocInfo = this.f('residenceDocInfo');
      // TODO последствия асинхронной работы с реактивной формой!
      setTimeout(() => {
        this.disableControls(migrationCardInfo, residenceDocInfo);
      });
      if (value?.code) {
        if (['2', '3'].includes(value?.code)) {
          setTimeout(() => {
            residenceDocInfo.enable();
          });
        }
        if (!['1', '6'].includes(value?.code)) {
          setTimeout(() => {
            migrationCardInfo.enable();
          });
        }
        this.docTypeList = this.getDocTypeList(value.code);
      }
    }
  }

  getDocTypeList(nationality?: string): Array<DictType>{
    nationality = nationality ?? this.f('nationality').value;
    return this.docTypeDict.filter(item => '1' == nationality ? item.code < 17 : item.code >= 17);
  }

  onCustomCheckForm(form: FormGroup): boolean {
    const excludeControls = [
      'migrationCardInfo',
      'residenceDocInfo'
    ];
    return Object.keys(form.controls).some((key: string) => {
      if (excludeControls.includes(key)) {
        return;
      }
      return form.get(key).invalid;
    });
  }

  /*является ли документ паспортом гражданина РФ*/
  isPassportOfCitizenRF(): boolean {
    const value = this.f('docType').value;
    return value && value.code && value.code === '1';
  }

  ngOnDestroy() {
    this.unsubscribable();
  }
}
