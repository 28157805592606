import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DictType, DopInfoType} from "../../../../models/dossier";
import {DossierService} from "../../../../services";
import {IDossierSubService} from "../IDossierSub.service";
import {DossierBasePageComponent} from "../dossierBasePage.component";
import {DopInfoDossierService} from "./dopInfoDossier.service";
import {DictionaryService, UserService} from "@amlCore/services";
import {AlertPanelService, BigSelectComponent} from "@amlCore/components";
import {Subject, Unsubscribable} from "rxjs";
import {ErrorFLKItemType} from "@amlCore/models";
import {NetworkDossierComponent} from "../components/network";
import {Utils} from "@amlCore/utils";
import {Validators} from "@angular/forms";
import {ClientTypeEnum} from "@amlCore/enums";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";
import {takeUntil} from "rxjs/operators";
import {CurrencyMapper} from "../../../../mapper/currency.mapper";


@Component({
  selector: 'app-dop-info-dossier',
  styleUrls: ['../dossier.css'],
  templateUrl: './dopInfoDossier.component.html'
})
export class DopInfoDossierComponent extends DossierBasePageComponent implements OnInit, OnDestroy {

  modelPage: DopInfoType;
  isTableTab = false;

  companyCategoryList: Array<DictType> = [];
  accountingServiceTypeList: Array<DictType> = [];
  taxationSystemList: Array<DictType> = [];
  estateList: Array<DictType> = [];
  financialPositionList: Array<DictType> = [];
  businessReputationList: Array<DictType> = [];
  isUL = false;
  isFL = false;
  isCapitalReq = false; // необходимо заполнить сведения уставного капитала. если значение поля true;
  isRequiredFieldCategory = true;
  showOtherSource = false;
  $destroy = new Subject();
  currencyMapper = new CurrencyMapper();
  @ViewChild('network') network: NetworkDossierComponent;
  @ViewChild('estate') estate: BigSelectComponent;

  constructor(protected dossierService: DossierService,
              protected route: ActivatedRoute,
              private dopInfoDossierService: DopInfoDossierService,
              protected dictSrv: DictionaryService,
              protected router: Router,
              protected userService: UserService,
              protected alertPanelSrv: AlertPanelService,
              protected modalService: NgbModal,
              protected fSaver: FileSaverService) {
    super(dossierService, route, dictSrv, router, userService, alertPanelSrv, modalService, fSaver);
  }

  ngOnInit() {
    // Инициализация вкладки
    this.modelPage = this.initPage();
    // Загрузка справочников
    this.companyCategoryList = this.dictSrv.getStaticData('companyCategory');
    this.accountingServiceTypeList = this.dictSrv.getStaticData('accountingServiceType');
    this.dictSrv.getRemoteDataCodeSort("taxationsystem").then((result => {
      this.taxationSystemList = result;
    }));
    this.f('dopInfo.estate').valueChanges.pipe(takeUntil(this.$destroy)).subscribe(value => {
      this.initEstate(value);
    });
    this.initEstate(this.f('dopInfo.estate')?.value);

    this.focusObject = {
      dopInfo: this.generateFocusObject(new DopInfoType())
    };

    //настройка формы в зависимости от типа клиента
    //при обновлении страницы с открытым подразделом досье, тип клиента может подгрузиться не сразу
    if(this.clientType)
      this.initFormByClientType();
    else{
      const svedClientSubscription = this.dossierService.svedClientDataSubject.pipe(takeUntil(this.$destroy)).subscribe((data) => {
        if(data!=null){
          this.initFormByClientType();
          svedClientSubscription.unsubscribe();
        }
      });
    }
  }

  private initFormByClientType(): void {
    if (ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR === this.clientType) {
      this.setControlValidator('dopInfo.category', true);
      this.isRequiredFieldCategory = true;
    } else if (ClientTypeEnum.LEGAL_ENTITY === this.clientType) {
      this.isUL = true;
      this.isRequiredFieldCategory = true;
      this.setControlValidator('dopInfo.category', true);
      this.isCapitalReq = true;
      this.setControlValidator('dopInfo.capital', this.isCapitalReq);
    } else {
      this._setNotReqFields();
    }
    const isFLDict = [ClientTypeEnum.INDIVIDUAL_PERSON, ClientTypeEnum.PRIVATE_PRACTITIONER].includes(this.clientType);
    this.dictSrv.getRemoteDataCodeSort(isFLDict ? "financialPosition" : "financialPositionUL").then((result => {
      this.financialPositionList = result;
    }));
    this.dictSrv.getRemoteDataCodeSort(isFLDict ? "businessReputation" : "businessReputationUL").then((result => {
      this.businessReputationList = result;
    }));

    this.isFL = ClientTypeEnum.INDIVIDUAL_PERSON == this.clientType;
    if(!this.isUL){
      this.disableControls(this.f('dopInfo.category'),
        this.f('dopInfo.sho'), this.f('dopInfo.goz'), this.f('dopInfo.orc'),
        this.f('dopInfo.leastCapital'), this.f('dopInfo.currencyReg'),
        this.f('dopInfo.capital'), this.f('dopInfo.currencyPaid'), this.f('dopInfo.share'));
    } else {
      this.loadRemoteData(['okv']);
    }
    if(this.isFL){
      this.disableControls(this.f('dopInfo.asset'),
        this.f('dopInfo.staffCount'), this.f('dopInfo.wageFund'), this.f('dopInfo.accountsDep'),
        this.f('dopInfo.activity'));
    } else{
      this.disableControls(this.f('dopInfo.selfEmployed'));
    }
    this.dictSrv.getRemoteData(this.isFL ? "sourceFunds" : "revenuedetails").then((result => {
      this.estateList = result;
    }));
  }

  private _setNotReqFields(): void {
    this.isRequiredFieldCategory = false;
    const category = this.f('dopInfo.category');
    category.setValidators([]);
    category.updateValueAndValidity();
  }


  /**
   * Инициализация модели
   * Преобразуем в нужный формат
   * @param model - данные сервера
   */
  initModel(model) {
    let newModel;
    this.partId = null;
    if (model) {
      const partIdList: string[] = Object.keys(model);
      newModel = model[partIdList[0]];
      Utils.deleteEmptyObj(newModel);
    } else {
      newModel = null;
    }
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  initEstate(value: DictType[]) {
    if (!this.panelForm.disabled) {
      this.showOtherSource = value?.some(v=> v?.code == "99");
      if (this.showOtherSource) {
        this.f('dopInfo.otherSource').enable();
      } else {
        this.f('dopInfo.otherSource').disable();
      }
    }
  }

  setControlValidator(path: string, isReq: boolean): void {
    if (isReq) {
      const control = this.f(path);
      control.setValidators([Validators.required]);
      control.updateValueAndValidity({onlySelf: true});
    }
  }

  openNetwork(event: ErrorFLKItemType) {
    if (event) {
      this.network.openWindow(this.dossierService.errorFLKTable);
    }
  }

  getService(): IDossierSubService {
    return this.dopInfoDossierService;
  }

  /**
   * Своя обработка фокусировки на ошибках для раздела
   * @param key - путь до поля с ошибкой
   */
  public isFocus(key: string): boolean {
    return this.focusObject.dopInfo ? this.focusObject.dopInfo[key] : false;
  }

  destroy() {
    // ТУТ писать реализацию ngOnDestroy для компонента
    this.$destroy.next();
    this.$destroy.complete();
  }

  openEstate(event: any) {
    this.estate.open();
  }
}
