import {DossierT40, DossierDate, DossierT50} from "./base.model";
import {DictType} from "./dictType.model";
import {PartItemArray} from "../partItemArray.model";
import {PaycardType} from "./paycardType.model";

export class AccountType extends PartItemArray {
  type: DictType = undefined;
  currcash: DictType = undefined;
  currency: DictType = undefined;
  accountNumber: DossierT40 = '';
  dateStart: DossierDate = '';
  dateEnd: DossierDate = '';
  division: DossierT50 = '';
  row: number;
  paycards: boolean = false;
  paycard?: Array<PaycardType>= undefined;
}
