import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {DictType, DossierList, StaffDossierModel} from "../../models";
import {DossierService} from "../../services";
import {DictionaryService, TableService, UserService, VisitedService} from "@amlCore/services";
import {DossierStatusEnum} from "./dossier";
import {DossierAccessEnum} from '@amlCore/enums';
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {StaffDossierComponent, StaffDossierEnum} from "./staffDossier";
import {Utils} from "@amlCore/utils";
import {forkJoin, Observable} from "rxjs";
import {ConfirmComponent} from "@amlCore/modals";
import {FileSaverService} from "ngx-filesaver";
import {ImportDossierResultComponent} from "./importDossierResult";
import {ImportDossierListResultModel} from "../../models/importDossierListResult.model";
import {InputFileComponent} from "../../../core/liteComponent";
import {RiskAccessEnum} from "../../../core/enum/riskAccessEnum";

@Component({
  selector: 'app-client-dossier-list',
  templateUrl: './clientDossierList.component.html',
  styleUrls: ['./clientDossierList.component.scss']
})
export class ClientDossierListComponent extends TableReactiveUrlController implements OnInit, OnDestroy {
  dossierList: Array<DossierList> = [];
  params: any;
  $selected = false;
  StaffDossierEnum = StaffDossierEnum;
  isReadOnly: boolean;
  identificationTypeList: Array<DictType> = [];
  isShowVisited = true;
  accessPageReadOnly: boolean;
  riskCount = 0;
  existsPrev = false;
  riskPanelShow = false;
  loadingAnalyzeRiske = false;
  importListResult: ImportDossierListResultModel;
  statusDossierEnum = DossierStatusEnum;
  dossierAccess = DossierAccessEnum;
  riskAccess = RiskAccessEnum;
  hasAccessDossierAll = this.userService.checkAccess(DossierAccessEnum.DOSSIER_ACCESS_ALL);
  private importModal: NgbModalRef;
  constructor(protected readonly activatedRoute: ActivatedRoute,
              protected readonly router: Router,
              private readonly dossierSrv: DossierService,
              protected readonly visitedService: VisitedService,
              protected readonly tableService: TableService,
              private readonly userService: UserService,
              private readonly modalService: NgbModal,
              private readonly dictSrv: DictionaryService,
              private fSaver: FileSaverService) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'created', dir: 'desc'});
  }

  ngOnInit() {
    this.createUniqueKeyForStore();
    this.activatedRoute.queryParams.subscribe((data) => {
      this.loadList(this.syncParams(data));
    });
    this.isReadOnly = this.dossierSrv.checkReadOnlyDossier();
    this.dictSrv.getRemoteData('typeidentification').then(data => {
      this.identificationTypeList = data;
    });
    this.accessPageReadOnly = this.checkPageReadOnly(DossierAccessEnum.DOSSIERACCESS);
  }

  /**
   * Проверка прав по доступу вкладки
   * @param typePage - тип вкладки
   */
  checkPageReadOnly(typePage: DossierAccessEnum): boolean {
    let result = true;
    const access = this.userService.getAccess(typePage);
    if (access && !access.readOnly) {
      result = false;
    }
    return result;
  }

 /**
  * метод обработки переключения поля
  * все/требующие решения
  * @param value Boolean парметр переключателя
  */
 onSwitchResult(value: boolean) {
   if (this.getFocusRowId()) {
     this.tableService.clear();
   }
   this.storeService.save(this.keyFilterResult, value);
   this.filter.result = value;
   this.onPageChange(this.getParams(this.filter));
 }

  /**
   * Открыть окно "Оттветственные сотрудники"
   * @param state - для чего открыть - назначение или удаление
   */
  openStaff(state: StaffDossierEnum) {
    const modalRef = this.modalService.open(StaffDossierComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      scrollable: true
    });
    // Передаем данные в компонент
    modalRef.componentInstance.open({
      state,
      selectedModelParam: this.getSelectedParam(this.getDossierNotClosedList()),
      filter: this.filter,
      search: this.filter?.search || '',
      total: this.total
    } as StaffDossierModel);
  }

  loadList(params?: any) {
    // Удаляем системные переменные
    Utils.removeKeysFromObj(params, '$refresh');
    const queryParams = {...this.params, ...this.getParamsv2(params)};
    if (typeof this.storeService.get(this.keyFilterResult) === "boolean") {
      queryParams["result"] = !this.storeService.get(this.keyFilterResult);
    }
    this.dossierSrv.getDossierList(queryParams).subscribe(data => {
      this.dossierList = data.content;
      this.total = data.totalElements;
      this.scrollRun();
    });
  }

  /** список незакрытых досье*/
  getDossierNotClosedList(): Array<DossierList>{
	      return this.dossierList.filter(item => item.status !== DossierStatusEnum.CLOSED);
  }
  /**
   * Имя из справочника по типу
   * @param code - тип
   */
  getIdentificationValue(code): string {
    const res = this.identificationTypeList.filter(item => item.code === code);
    return res.length === 1 ? res[0].name : '';
  }

  /**
   * Получить тип лица
   * @param item - анкета
   */
  getTypeClient(item: DossierList): string {
    return {
      1: 'ЮЛ',
      2: 'ФЛ',
      3: 'ИП',
      4: 'ФЛ ЧП',
      5: 'ИНБОЮЛ',
    }[item.clientType] || '';
  }

  edit(data: DossierList) {
    this.tableService.saveScrollPosition(this.commonKeyStore);
    this.router.navigate([`dossier/${data.id}/svedClient`]);
  }
  /**
   * Удаление выбранных записей
   */
  deleteSelected(): void {
    const deleteReq = [];
    const deleteItem = [];
    this.getSelectedModel(this.dossierList).forEach((item: DossierList) => {
      deleteReq.push(this.deleteDossier(item.id));
      deleteItem.push(item);
    });
    forkJoin(deleteReq).subscribe(() => {
      deleteItem.forEach(item => this.selectRow(item));
      this.onPageChange({$refresh: Math.random()});
    });
  }

  deleteDossier(dossierId: string): Observable<any> {
    return this.dossierSrv.deleteDossier(dossierId);
  }

  /**
   * Метод вызываемый после импорта
   */
  importLoad(result: any){
    const list = result.data as ImportDossierListResultModel;
    let comp = result.comp as InputFileComponent;
    let event = result.event;
    if(result.repeatSend){
      if(comp.queryParams.get("okvedUpdate")=="true"){
        this.importListResult.okvedError = [];
      }
      Object.keys(list).forEach(key => {
        this.importListResult[key] = [ ...this.importListResult[key], ...list[key]];
      });
    } else {
      this.importListResult = list;
    }


    if(list?.clientIdError?.length){
      const modal = this.modalService.open(ConfirmComponent);
      modal.componentInstance.title = "Внимание";
      modal.componentInstance.text = `Найдено ${this.importListResult?.clientIdError?.length} совпадающих идентификаторов Досье в клиентской базе с файлами. Продолжить импорт файлов? Будет создана новая версия досье.`;
      modal.componentInstance.okBtn = "Ок";
      modal.componentInstance.cancelBtn = "Отмена";
      modal.componentInstance.hideCloseBtn = true;
      modal.result.then(() => {
        comp.queryParams = comp.queryParams.set("forceUpdate","true");
        const filteredFiles = Array.from(event.target.files).filter(file => {
          return this.importListResult?.clientIdError?.some(err => err.fileName === (file as File).name);
        });
        this.importListResult.clientIdError = [];
        comp.sendFiles(filteredFiles, event, true);
      }, () => {
        this.openImportResultWindow(this.importListResult, comp ,event);
      });
    } else {
      this.openImportResultWindow(this.importListResult, comp, event);
    }
  }

  onPageChange(params) {
    this.navigate({...params, ...this.getParams(this.filter)});
    this.resetSelectedAll();
  }

  onSearch() {
    const params = this.getSearchPage(this.filter);
    this.onPageChange(params);
  }

  analyzeRisk() {
    this.riskPanelShow = false;
    this.loadingAnalyzeRiske = true;
    // Получаем список выбранных досье - формируем список объектов с checkObjectId = id досье
    const selectedDossierList = this.getSelectedModel(this.getDossierNotClosedList()).map((item: DossierList) => {
      return {checkObjectId: item.id};
    });
    this.dossierSrv.analyzeRisk(selectedDossierList).subscribe(data => {
      if (data) {
        this.riskCount = 0;
        this.riskPanelShow = true;
        this.existsPrev = false;
        (data as Array<any>).forEach(item => {
          this.riskCount += item.totalCount;
          if(item.existsPrev){
             this.existsPrev = true;
          }
        });
      }
      this.loadingAnalyzeRiske = false;
    }, error => {
      this.loadingAnalyzeRiske = true;
    });
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data) {
    const filter = this.tableService.getFilterSubject(data);
    return this.getFilterPlanObject(filter);
  }

  ngOnDestroy() {
    this.checkScrollOptions();
  }

  private openImportResultWindow(list, comp, event) {
    if(!this.importModal){
      this.importModal = this.modalService.open(ImportDossierResultComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'lg',
        scrollable: true
      });
      this.importModal.componentInstance.importOkved.subscribe(()=>{
        comp.queryParams = comp.queryParams.set("okvedUpdate","true");
        const filteredFiles = Array.from(event.target.files).filter(file => {
          return this.importListResult?.okvedError?.some(err => err.fileName === (file as File).name);
        });
        comp.sendFiles(filteredFiles, event, true);
      });
      this.importModal.result.finally(() => {
        delete this.importModal;
        comp.reset();
        // Подмешиваем случайную переменную для реализации обновления при импорте
        // иначе роутинг не перезапустится, тк параметры запроса не меняются, а реализация через
        // onSameurlNavigation: 'reload' и runGuardsAndResolvers: 'always' может привести к не нужным перезагрузкам
        this.onPageChange({$refresh: Math.random()});
      });
    }

    // Передаем данные в компонент
    this.importModal.componentInstance.open(list);
  }
}
