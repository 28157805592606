import { Component, OnInit, OnDestroy } from "@angular/core";
import { BaseFilterController } from "../BaseFilterController";
import { FormBuilder, Validators } from "@angular/forms";
import { IDictItemType } from "../../interfaces";
import { Unsubscribable } from "rxjs";
import {DictionaryService, TableService, ValidatorService} from "@amlCore/services";
import { ClientTypeEnum } from "@amlCore/enums";
import {ConsolidatedFilter} from "../../models/consolidatedFilter.model";

@Component({
  selector: "app-consolidated-list-filter",
  templateUrl: "./consolidated-list-filter.component.html",
})

export class ConsolidatedListFilterComponent extends BaseFilterController implements OnInit, OnDestroy {
  public selectObj = {
    typeSubject: [] as IDictItemType[]
  };
  public filterObjectModel = new ConsolidatedFilter();
  public subjectTypeUnsubscrible: Unsubscribable;
  placeholder = 'Поиск по ФИО, наименованию';

  constructor(private fb: FormBuilder,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              protected validationSrv: ValidatorService) {
    super(tableSrv);
  }
  ngOnInit() {
    this.init();
    this.dictSrv.loadTypeClient().then(result => {
      this.selectObj.typeSubject = result.filter((item) => [ClientTypeEnum.LEGAL_ENTITY, ClientTypeEnum.INDIVIDUAL_PERSON].includes(item.code));
    });
    this.subjectTypeUnsubscrible = this.formModel.get("subjectType").valueChanges.subscribe((typeCode: string) => {
      this.selectedClientType({ type: (typeCode || '') });
    });
  }

  public selectedClientType(data: any) {
    if (data.type === '') {
      return;
    }
    const currentSubjectTypeCode: string = data.type;
    const subjectTypeMap = {
      1: ["name"],
      2: ["name", "birthDate", "number", 'idSubject'],
    };
    const [disableSubjectTypeCode] = Object.keys(subjectTypeMap).filter((typeCode) => typeCode !== currentSubjectTypeCode);

    subjectTypeMap[disableSubjectTypeCode].forEach((field: string) => {
      this.formModel.get(field).disable();
    });
    subjectTypeMap[currentSubjectTypeCode].forEach((field: string) => {
      this.formModel.get(field).enable();
    });
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig =  new ConsolidatedFilter();
    formConfig.birthDate = [null, this.validationSrv.getValidation({
      isDate: true
    })];
    return this.fb.group(formConfig);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subjectTypeUnsubscrible.unsubscribe();
  }

  getStartFilterModel(): any {
    return {
      subjectType: ClientTypeEnum.LEGAL_ENTITY // тип субъекта
    } as ConsolidatedFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      subjectType: 'Тип субъекта',
      name: 'ФИО/Наименование',
      birthDate: 'Дата рождения',
      number: 'Номер документа',
      idSubject: 'Идентификатор'
    };
  }
}
