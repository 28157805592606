import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ApiService} from "@amlCore/services";
import {HttpParams} from "@angular/common/http";
import {Utils} from "@amlCore/utils";
import {concat, Observable} from "rxjs";
import {last} from "rxjs/operators";

@Component({
  selector: 'app-input-file',
  template: `
    <input class="invisible" type="file" #file (change)="fileChange($event)" [accept]="accept" style="width:0" [multiple]="isMultiple" repeatUpload="repeatUpload"/>
    <button [class]="appClass" (click)="file.click()" [disabled]="loader || isReadOnly">
      <span *ngIf="!loader"><ng-content></ng-content></span>
      <span *ngIf="loader"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Идет загрузка...</span>
    </button>
  `
})
export class InputFileComponent {

  @ViewChild("file") file: ElementRef;
  loader = false;
  // Можно переопределить класс для кнопки
  @Input() appClass = 'btn btn-primary mb-2';
  // URL загрузки
  @Input() url: string;
  //параметры запроса
  @Input() queryParams: HttpParams = new HttpParams();
  // Фильтр форматов (можно указывать через запятую - .xml,.csv)
  @Input() accept = '.xml';
  // Признак блокировки кнопки - для формы просмотра
  @Input() isReadOnly: boolean;
  // Признак возможности загрузки нескольких файлов
  @Input() isMultiple: boolean;
  // Признак повторной загрузки
  @Input() repeatUpload: boolean;
  // Действие после успешной загрузки
  @Output() callback: any = new EventEmitter<any>();
  // Действие после неудачной загрузки
  @Output() callbackError: any = new EventEmitter<any>();
  // Начало отправки файлов
  @Output() onSendStart: any = new EventEmitter<any>();
  // Можно переоределить отправку файлов
  @Output() doSendFiles: any = new EventEmitter<any>();
  // файлы больших размеров
  @Output() overSizeFiles: any = new EventEmitter<any>();
  // Имя объекта для загрузки файла
  @Input() formDataName = 'uploadFile';
  // Максимальный размер файла
  @Input() maxSize: boolean;
  // Максимальное количество документов, импортируемых одним запросом
  @Input() maxPacketSize: number;
  // Нужно ли остановить если есть файл большого размера
  @Input() stopIfOverSize: boolean;
  // Нужно ли остановить если есть файл минимального размера
  @Input() stopIfLowSize: boolean;
  // Минимальный размер файла
  @Input() minSize: number;
  // Собственный текст ошибки на минимальный размер файла
  @Input() minSizeMessage: string;
  // список загружаемых файлов
  @Output() fileList?: any = new EventEmitter<any>();
  // Шаблон для имени файла
  @Input() fileNameRegex: string;
  constructor(private api: ApiService) {
  }

  fileChange(event) {
    // event.target.files?.forEach(item => console.log(item.name));
    // console.log(event.target.files[0].name);
    const fileList: FileList = event.target.files;
    this.sendFiles(fileList, event);
  }

  /**
   * Обработка загруженных файлов
   * @param fileList
   * @param event
   * @param repeatSend
   */
  sendFiles(fileList, event, repeatSend?: boolean){
    if (fileList.length > 0) {
      this.loader = true;
      const filesToSent = [];
      let maxSizeError = false;
      let minSizeError = false;
      let overSizeArr = [];
      if(this.isMultiple){
        for (let i = 0; i < fileList.length; i++) {
          if(this.maxSize && fileList[i].size > this.maxSize){
            maxSizeError = true;
            overSizeArr.push({fileName: fileList[i].name, msg: `Превышен максимальный размер файла (${Utils.formatBytes(this.maxSize, 0)})!`, success: false,
              receivedDocs:[] });
          } else if (this.minSize && fileList[i].size < this.minSize) {
           minSizeError = true;
          } else {
            filesToSent.push(fileList[i]);
          }
        }
      } else {
        if(this.maxSize && fileList[0].size > this.maxSize) {
          overSizeArr.push({fileName: fileList[0].name, msg: `Превышен максимальный размер файла (${Utils.formatBytes(this.maxSize, 0)})!`, success: false,
            receivedDocs:[] });
        }
        if (this.minSize && fileList[0].size < this.minSize) {
          minSizeError = true;
        }

        if(this.fileNameRegex && !fileList[0].name.match(new RegExp(this.fileNameRegex, "gi"))){
          this.callbackError.emit(`Некорректное имя файла (${fileList[0].name})!`);
          this.reset();
          return;
        }
        filesToSent.push(fileList[0]);
      }

      if(maxSizeError && this.stopIfOverSize){
        this.reset();
        this.callbackError.emit(`Превышен максимальный размер файла (${Utils.formatBytes(this.maxSize, 0)})!`);
        return;
      }

      if(minSizeError && this.stopIfLowSize){
        this.reset();
        this.callbackError.emit(this.minSizeMessage);
        return;
      }

      if (overSizeArr.length) {
        this.overSizeFiles.emit(overSizeArr);
      }

      this.onSendStart.emit(filesToSent);
      if (overSizeArr.length !== fileList.length) {
        if(this.doSendFiles.observers?.length>0){
          this.doSendFiles.emit(repeatSend);
        } else {
          this.fileList.emit(filesToSent);
          this.doSend(filesToSent, overSizeArr, repeatSend);
        }
      } else {
        this.reset();
        this.callback.emit(overSizeArr);
      }
    }
  }

  /**
   * Отправка файлов
   * @param filesToSent
   * @param overSizeArr
   * @param repeatSend
   */
  doSend(filesToSent: any[], overSizeArr: any[], repeatSend: boolean){
    const inputEvent = event;
    const request: Observable<any> = (!this.maxPacketSize || filesToSent.length<this.maxPacketSize) ?
      this.api.sendFormData(this.url, this.getFormData(filesToSent), this.queryParams) :
      concat(...this.createPacketRequests(filesToSent)).pipe(last());
    request.subscribe(data =>{
      this.afterImport(data, overSizeArr, inputEvent, repeatSend)}, error => {
      this.reset();
      this.callbackError.emit(error);
    });
  }

  private afterImport(data, overSizeArr, event, repeatSend?: boolean){
    if (Array.isArray(data)) {
      data = [...data, ...overSizeArr];
    }
    if(this.repeatUpload){
      this.callback.emit({data: data, comp: this, event: event, repeatSend:repeatSend});
    } else {
      this.reset();
      this.callback.emit(data);
    }
  }

  private createPacketRequests(filesToSent: File[]): Observable<any>[]{
    const packets: File[][] = filesToSent.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/this.maxPacketSize)
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }
      resultArray[chunkIndex].push(item)

      return resultArray;
    }, []);
    const requests:Observable<any>[] = [];
    for(let i=0; i<packets.length; i++){
      const params = i!=packets.length-1 ? this.queryParams.append("notLastPart", "true") : this.queryParams;
      requests.push(this.api.sendFormData(this.url, this.getFormData(packets[i]), params));
    }
    return requests;
  }

  private getFormData(files: File[]): FormData {
    const formData: FormData = new FormData();
    files.forEach(file => {
      formData.append(this.formDataName, file);
    })
    return formData;
  }

  reset() {
    this.loader = false;
    this.file.nativeElement.value = "";
    this.queryParams.keys().forEach(key=>{
      this.queryParams = this.queryParams.delete(key);
    });
  }

}
