import {Component, OnInit} from "@angular/core";
import {DossierService} from "../../../../../services";
import {DictType} from "../../../../../models/dossier";
import {DictionaryService} from "@amlCore/services";
import {DossierBaseModel} from "../../dossierBaseModel";
import {DossierBaseSubComponent} from "../dossierBaseSub.component";
import {AlertPanelService} from "@amlCore/components";
import {DossierAccessEnum} from "@amlCore/enums";
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'app-reg-info-cp-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './regInfoCPDossier.component.html'
})
export class RegInfoCPDossierComponent extends DossierBaseSubComponent implements OnInit {
  private readonly _codeVidViewOther = '0';
  dossierAccessEnum = DossierAccessEnum;
  vidList: DictType[] = [];
  isViewOtherField = false;
  isViewNumCertificate = false;
  isViewInnOgrn = false;

  constructor(protected dossierService: DossierService,
              protected dictSrv: DictionaryService,
              protected dossierBaseModel: DossierBaseModel,
              protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  ngOnInit(): void {
    this._initVid();
    this.dictSrv.getRemoteData('typeactivityprivateentrepreneur').then((result: DictType[]) => {
      this.vidList = result.filter(r => r.code !=0).concat(result.filter(r => r.code == 0));
    });

    this.f('vid').valueChanges.subscribe((value: DictType) => {
      this._initVid(value);
    })
  }

  private _initVid(value?: DictType): void {
    const vidValue = value || this.f('vid').value;
    this.isViewOtherField = this._viewOtherField(vidValue);
    this.isViewNumCertificate = ['1','2'].includes(vidValue?.code);
    this.isViewInnOgrn = vidValue?.code == '3';
    if(!this.isReadOnly){
      setTimeout(() => {
        this._toggleFieldOther(this.isViewOtherField);
        this.isViewNumCertificate ? this.enableControls(this.f('numCertificate'))
          : this.disableControls(this.f('numCertificate'));
        this.isViewInnOgrn ? this.enableControls(this.f('innCRO'), this.f('ogrnCRO'))
          : this.disableControls(this.f('innCRO'), this.f('ogrnCRO'));
      });
    }

  }

  private _viewOtherField(otherValue: DictType): boolean {
    if (!otherValue) {
      return false;
    }
    return this._codeVidViewOther === otherValue.code;
  }

  private _toggleFieldOther(value: boolean): void {
    const otherControl = this.f('otherVid') as AbstractControl;
    value && !this.isReadOnly ? otherControl.enable() : otherControl.disable();
  }
}
