import {Component, OnInit, OnDestroy} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {CheckClients} from "../../models/checkClients";
import {CheckClientsFilter} from "@amlCore/models";
import {DossierService} from "../../services";
import {Utils} from "@amlCore/utils";
import {TableService} from "@amlCore/services";
import {saveAs} from "file-saver";
import {IDictionaryItem, IDictItemType} from "@amlCore/interfaces";
import {DictionaryService, VisitedService} from "@amlCore/services";
import {IDossierPage} from "../../interfaces";
import {AlertPanelComponent} from "@amlCore/components";
import {ConfirmComponent} from "@amlCore/modals";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Observable} from "rxjs";

@Component({
  selector: 'app-client-check-clients',
  templateUrl: './checkClients.component.html'
})
export class CheckClientsComponent extends TableReactiveUrlController implements OnInit, OnDestroy, IDossierPage {
  private readonly MAX_EXPORTS_CHECKS = 1000; // Макс. кол-во выгружаемых проверок

  public checkClientsList: CheckClients[] = []; // список результатов проверок
  public isShowVisited = true;
  public loadingExcelFile = false;
  public insideDossier = false;
  isDossierPart = false; // Признак, что раздел в досье
  idDossier: string; // id досье если isDossierPart === true
  private npa: IDictionaryItem<{
    id: string;
    name: string;
  }>[] = [];
  private typeSubjects: IDictItemType[] = [];
  private riskLevels: IDictItemType[] = [];
  private resultCheckList: IDictItemType[] = [];

  constructor(protected dictSrv: DictionaryService,
              protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected tableService: TableService,
              protected router: Router,
              private dossierSrv: DossierService,
              private readonly modalService: NgbModal) {
    super(router, activatedRoute, visitedService, tableService,
      {
        sort: 'checkDate',
        dir: 'desc'
      });
  }

  ngOnInit() {
    this.dictSrv.getRemoteData('npa').then((result) => {
      this.npa = result.map((item: any) => {return {id: item.code, name: item.name};});
    });
    this.dictSrv.getRemoteData('typesubjects').then((result) => {
      this.typeSubjects = result;
    });
    this.dictSrv.getRemoteData('risklevel').then((result) => {
      this.riskLevels = result;
    });
    this.dictSrv.getRemoteData('resultchecking').then(result => {
      this.resultCheckList = result;
    });
    this.isDossierPart = this.activatedRoute.snapshot.data.isDossierPart;
    this.idDossier = this.activatedRoute.snapshot.parent.params.id;
    this.createUniqueKeyForStore();
    this.idDossier = this.activatedRoute.snapshot.parent.params.id;
    /* если находимся в новом досье которое еще только создается, то список проверок не запрашиваем */
    if (this.activatedRoute.routeConfig.data.hasOwnProperty('isDossierPart') && !this.idDossier) {
      this.total = 0;
      return;
    }
    this.activatedRoute.queryParams.subscribe(data => {
      this.loadList(this.syncParams(data));
    });
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data) {
    const filter = this.tableService.getFilterSubject(data);
    return this.getFilterPlanObject(filter);
  }

  exportExcelHandler(): void {
    const selected = this.getSelectedModel(this.checkClientsList) as Array<CheckClients>;
    if (selected.length == 0 && this.total > this.MAX_EXPORTS_CHECKS) {
      const modal = this.modalService.open(ConfirmComponent);
      modal.componentInstance.text =
          'Результаты поиска превышают допустимое количество записей для выгрузки. \n' +
          `В случае продолжения в файл будут выгружены первые ${this.MAX_EXPORTS_CHECKS} найденных записей.`;
      modal.result.then(() => {
        this.exportExcelFile()
      }, () => {})
      return;
    }
    this.exportExcelFile(selected);
  }

  /**
   * Экспорт в EXCEL
   */
  public exportExcelFile(selected?: Array<CheckClients>) {
    const successStatusId = 4;
    this.loadingExcelFile = true;
   this.getExportMethod(selected).subscribe(data => {
      if (data.type === successStatusId) {
        this.loadingExcelFile = false;
        saveAs(data.body, Utils.getFileNameFromContentDisposition(data));
      }
    }, (error) => {
      console.warn(error);
      this.loadingExcelFile = false;
    });
  }

  private getExportMethod(selected?: Array<CheckClients>): Observable<any>{
    if(selected?.length>0){
      const ids = selected.map(s => s.id);
      return this.dossierSrv.exportCheckClientListExcel(ids);
    } else {
      const filter = this.getFilterObject(this.filter);
      const queryParams = this.getParamsv2({...this.params, ...filter});
      if (this.idDossier !== '') {
        queryParams["idDossier"] = this.idDossier;
      }
      queryParams["size"] = this.MAX_EXPORTS_CHECKS;
      const resultValue = this.storeService.get(this.keyFilterResult);
      if (typeof resultValue === "boolean") {
        queryParams["result"] = !resultValue;
      }
      return this.dossierSrv.exportCheckClientsExcel(queryParams);
    }
  }

  loadList(params?: CheckClientsFilter) {
    const queryParams = {...this.params, ...this.getParamsv2(params)};
    const resultValue = this.storeService.get(this.keyFilterResult);
    if (typeof resultValue === "boolean") {
      queryParams["result"] = !resultValue;
    }
    if (this.idDossier) {
      this.dossierSrv.getCheckClientsListForDossier(queryParams, this.idDossier).subscribe(data => {
        this.isSelectedAll = false;
        this.checkClientsList = data.content;
        this.total = data.totalElements;
        this.scrollRun();
      });
    } else {
      this.dossierSrv.getCheckClientsList(queryParams).subscribe(data => {
        this.isSelectedAll = false;
        this.checkClientsList = data.content;
        this.total = data.totalElements;
        this.scrollRun();
      });
    }
  }

  onSwitchResult(value: boolean) {
    if (this.getFocusRowId()) {
      this.tableService.clear();
    }
    this.storeService.save(this.keyFilterResult, value);
    this.filter.result = value;
    this.onPageChange(this.getParams(this.filter) as CheckClientsFilter);
  }

  /**
   * Метод простого поиска
   */
  public onSearch() {
    const params = this.getSearchPage(this.filter) as CheckClientsFilter;
    this.onPageChange(params);
  }

  onPageChange(params: CheckClientsFilter) {
    this.navigate({...params, ...this.getParams(this.filter)});
  }

  viewForm(check: CheckClients) {
    this.tableService.saveScrollPosition(this.commonKeyStore);
    this.router.navigate(['checkForm', check.id]);
  }

  openDossier(check: CheckClients) {
    if (check.dossierInfo.clientInfo.state !== 1) {
      this.dossierSrv.setPageId(check.dossierInfo.clientInfo.partId);
    } else {
      this.dossierSrv.setPageId(null);
    }
    this.router.navigate([`dossier/${check.dossierInfo.clientInfo.dossierId}/${check.dossierInfo.clientInfo.typeSubject}`])
  }

  openDossierRoot(check: CheckClients) {
    if (check.dossierInfo.rootClient.state !== 1) {
      this.dossierSrv.setPageId(check.dossierInfo.rootClient.partId);
    }
    this.router.navigate([`dossier/${check.dossierInfo.rootClient.dossierId}/${check.dossierInfo.rootClient.typeSubject}`])
  }

  getNpa(id: string) {
    return this.dictSrv.getNpa(id, this.npa);
  }

  getTypeSubjectName(code: string): string {
    return this.dictSrv.getDictNameByCode(code, this.typeSubjects);
  }

  getRiskLevelName(code: string): string {
    return this.dictSrv.getDictNameByCode(code, this.riskLevels);
  }

  ngOnDestroy() {
    this.checkScrollOptions();
  }

  setAlertPanel(alertObjToSave: AlertPanelComponent): void {
  }

  getCheckResultName(checkResult: string) {
    return this.dictSrv.getDictNameByCode(checkResult, this.resultCheckList);
  }
}
