import {Directive, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DossierAccessEnum} from '@amlCore/enums';
import {
  FormArray,
  FormGroup
} from '@angular/forms';
import {DossierService} from '../../../../services';
import {DictionaryService} from '@amlCore/services';
import {DossierBaseComponent} from '../dossierBase.component';
import {Unsubscribable} from 'rxjs';
import {AlertPanelService} from '@amlCore/components';
import {CustomValidatorError} from "@amlCore/utils";
import {ErrorFLKItemType} from "@amlCore/models";
import {IFocus} from "@amlCore/interfaces";

/**
 * Базовый класс для подкомпонентов в досье на основе formArray
 */
@Directive()
export abstract class DossierBaseArrayComponent extends DossierBaseComponent implements OnChanges, IFocus {
  @Input() isReadOnly: boolean = false;
  @Input() typePage: DossierAccessEnum;
  @Input() formArrayApp: FormArray;

  public savedErrorFLKData: ErrorFLKItemType;
  isDisabled: boolean;

  protected constructor(protected dossierService: DossierService,
                        protected dictSrv: DictionaryService,
                        protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  fGroup(index: number): FormGroup {
    return this.formArrayApp.controls[index] as FormGroup;
  }


  add(data?: any, index?: number): void {
    const group = this.createCustomFormGroup(data);
    if (this.formArrayApp.controls.length >= 1) {
      group.get('row').setValue(this.createUniqueId());
    } else if (this.formArrayApp.controls.length === 0) {
      group.get('row').setValue(0);
    }
    if(index != undefined){
      this.formArrayApp.insert(index, group);
    } else {
      this.formArrayApp.push(group);
    }
  }

  abstract createCustomFormGroup(data?: any): FormGroup;


  f(field: string) {
    return this.formArrayApp.get(field);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errorFLKData) {
      this.clearErrorFLKListFields();
      if (this.errorFLKData) {
        this.formArrayApp.controls.forEach(control => {
          const row = control.get('row')?.value;
          const errors = this.errorFLKData
            ? this.errorFLKData[row]
            : null;
          if (errors) {
            errors.forEach(error => {
              const result = {
                customValidator: {
                  errorMsg: error.msg,
                  errorMsgBig: error.reason,
                  withBigMsg: true,
                  withoutSubmitted: true
                } as CustomValidatorError
              };
              const field = control.get(error.path);

              if (field) {
                setTimeout(() => field.setErrors(result));
              } else {
                throw new Error(`Неизвестный путь ${ error.path }`);
              }
            });
          }
        });
      }
      this.savedErrorFLKData = this.errorFLKData;
    }
  }

  /**
   * Очистка ошибок по ФЛК
   */
  clearErrorFLKListFields(): void {
    if (this.savedErrorFLKData) {
      this.formArrayApp.controls.forEach(control => {
        const row = control.get('row')?.value;
        const errors = this.savedErrorFLKData
          ? this.savedErrorFLKData[row]
          : null;
        if (errors) {
          errors.forEach(error => {
            const field = control.get(error.path);
            if (field) {
              setTimeout(() => field.setErrors(null));
            }
            this.savedErrorFLKData = this.errorFLKData;
          });
        }
      });
    }
  }

   createUniqueId(): number {
    let rowId = 0;
    const ids = [];

    this.formArrayApp.controls.forEach((control) => {
      ids.push(control.get('row').value);
    });

    while (ids.includes(rowId)) {
      rowId++;
    }
    return rowId;
  }

  focus(): void {
    const error = this.dossierService.errorFLKTable;
    if (error) {
      setTimeout(() => {
          this.focusObject = {[error.row] : this.generObj({}, error.path, true) };
      }, 0);
      setTimeout(() => {
        this.focusObject = {};
      }, 100);
    }
  }

}
