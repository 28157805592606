import {Component, OnDestroy, OnInit} from '@angular/core';
import {DossierService} from '../../../../../services';
import {DictionaryService} from '@amlCore/services';
import {DossierBaseModel} from '../../dossierBaseModel';
import {AlertPanelService} from '@amlCore/components';
import {
  FormBuilder,
} from '@angular/forms';
import {DossierBaseArrayComponent} from "../dossierBaseArray.component";
import {DocumentInfoType} from "../../../../../models";

@Component({
  selector: 'app-document-infos-dossier',
  styleUrls: ['../../dossier.css'],
  templateUrl: './documentInfosDossier.component.html'
})
export class DocumentInfosDossierComponent extends DossierBaseArrayComponent {

  constructor(protected fb: FormBuilder,
                        protected dossierBaseModel: DossierBaseModel,
                        protected dossierService: DossierService,
                        protected dictSrv: DictionaryService,
                        protected alertPanelService: AlertPanelService) {
    super(dossierService, dictSrv, alertPanelService);
  }

  createCustomFormGroup(data = {}) {
    const formGroup = this.dossierBaseModel.getDocumentInfoType(this.typePage, <DocumentInfoType>data);
    formGroup.addControl('row', this.fb.control([this.createUniqueId()]));
    return formGroup;
  }
}
