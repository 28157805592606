import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UserService} from '@amlCore/services';
import {DossierAccessEnum, DossierAccessEnumValue} from '@amlCore/enums';
import {DossierService} from '../../../services';
import {Utils} from '@amlCore/utils';
import {SvedClientDossierService} from './svedClient';
import {SvedAUDossierService} from './svedAU';
import {SvedIOUDossierService} from './svedIOU';
import {SvedPredstDossierService} from './svedPredst';
import {SvedBVDossierService} from './svedBV';
import {SvedTargetDossierService} from './svedTarget';
import {SvedVPRDossierService} from './svedVPR';
import {BankAccountsDossierService} from './bankAccounts';
import {DokReestrDossierService} from './dokReestr';
import {OtherAccountsDossierService} from './otherAccounts';
import {AttributesDossierService} from './attributes';
import {RiskAssessDossierService} from "./riskAssess";
import {DopInfoDossierService} from './dopInfo';
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars';
import {IVersionCallback, VersionComponent} from './components/version';
import {faEye} from '@fortawesome/free-solid-svg-icons/faEye';
import {AlertPanelComponent, AlertPanelService} from '@amlCore/components';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {DossierStatusEnum} from './dossierStatusEnum';
import {PartnerDossierService} from './partner';
import {Subscription, throwError, Unsubscribable} from 'rxjs';
import {IDossierPage} from '../../../interfaces';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {ClientInfo, DossierStatusModel} from '../../../models';
import {faCogs} from '@fortawesome/free-solid-svg-icons/faCogs';
import {SetupFLKWindowComponent} from '../../setupFLK';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faClipboardCheck} from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import {faClipboardList} from '@fortawesome/free-solid-svg-icons/faClipboardList';
import {FormGroup} from '@angular/forms';
import {AutoUnsub} from '../../../../core/decorators';
import {RiskAccessEnum} from "../../../../core/enum/riskAccessEnum";
import {finalize, skip} from "rxjs/operators";

@Component({
  selector: 'app-client-dossier-panel',
  providers: [
    SvedClientDossierService,
    SvedAUDossierService,
    SvedIOUDossierService,
    SvedPredstDossierService,
    SvedBVDossierService,
    SvedVPRDossierService,
    SvedTargetDossierService,
    BankAccountsDossierService,
    DopInfoDossierService,
    OtherAccountsDossierService,
    AttributesDossierService,
    RiskAssessDossierService,
    DokReestrDossierService,
    PartnerDossierService
  ],
  templateUrl: './dossier.component.html',
  styleUrls: ['./dossier.css'],
})
@AutoUnsub()
export class DossierComponent implements OnInit, OnDestroy {

  @ViewChild('alertObjSave', {static: true}) alertPanelComponent: AlertPanelComponent;
  @ViewChild('alertObjDossier', {static: true}) alertObjDossier: AlertPanelComponent;
  @ViewChild('versionSelect', {static: true}) versionSelect: VersionComponent;
  topPage = Utils.scrollTopSmooth;
  opened = true;
  isEdit: boolean;
  isReadOnly = false;
  isLoaded = true;
  accessEnum = DossierAccessEnum;
  riskAccessEnum = RiskAccessEnum;
  statsEnum = DossierStatusEnum;
  activePart;
  dossierId;
  status;
  activePartComponent: IDossierPage; // ссылка на активный компонент вкладки (для вызова методов сохранения и тп)
  isHistory; // Признак отображения досье в режиме истории версий
  versionHistory; // Версия истории
  loadingAnalyzeRisk = false;
  riskPanelShow = false;
  riskCount = 0;
  existsPrev = false;
  icons = {
    bars: faBars,
    eye: faEye,
    waring: faExclamationTriangle,
    spinner: faSpinner,
    gear: faCogs,
    transactionsCheck: faClipboardCheck,
    transactionsList: faClipboardList,
  };
  routerEventsSub: Unsubscribable;
  hasCheckClientResolve: boolean;
  hasCheckTransactionResolve: boolean;
  formPanelRef: FormGroup // ссылка на форму
  svedClientDataSub: Subscription;
  orgName = '';
  disabledSaveButton = false;
  sSaveButtonSubscribe: Subscription;
  isErrorFLKSubscribe: Subscription;
  constructor(private readonly route: ActivatedRoute,
              private readonly userSrv: UserService,
              private readonly svedClientDossierService: SvedClientDossierService,
              private readonly svedAUDossierService: SvedAUDossierService,
              private readonly svedIOUDossierService: SvedIOUDossierService,
              private readonly svedPredstDossierService: SvedPredstDossierService,
              private readonly svedBVDossierService: SvedBVDossierService,
              private readonly svedVPRDossierService: SvedVPRDossierService,
              private readonly svedTargetDossierService: SvedTargetDossierService,
              private readonly bankAccountsDossierService: BankAccountsDossierService,
              private readonly dopInfoDossierService: DopInfoDossierService,
              private readonly otherAccountsDossierService: OtherAccountsDossierService,
              private readonly attributesDossierService: AttributesDossierService,
              private readonly riskAccessDossierService: RiskAssessDossierService,
              private readonly dokReestrDossierService: DokReestrDossierService,
              private readonly partnerDossierService: PartnerDossierService,
              private readonly dossierService: DossierService,
              private readonly router: Router,
              private readonly userService: UserService,
              private readonly alertPanelSrv: AlertPanelService,
              private readonly modalService: NgbModal) {
  }

  goBack(): void {
    if (history.length === 1) {
      this.router.navigateByUrl('/cabinet/dossier/list')
      return;
    }
    history.back();
  }

  expandedNav(): void {
    this.opened = !this.opened;
  }

  /**
   * При активации саб комопнента получаем ссылку на него
   * @param componentRef - ссылка на компонент
   */
  onActivate(componentRef): void {
    if (componentRef) {
      this.activePartComponent = componentRef as IDossierPage;
      this.activePartComponent.isSave = true;
      this.activePartComponent.setAlertPanel(this.alertPanelComponent);
      if (this.activePartComponent.setVersionComponentDossierPage) {
        this.activePartComponent.setVersionComponentDossierPage(this.versionSelect);
      }
      // Проверяем наличие св-ва getForm в объекте и его цепочки прототипов, т.к. не все компоненты наследуют класс DossierBaseSubComponent
      if (this.activePartComponent.getForm) {
        this.activePartComponent.getForm().then(form => {
          if (form) {
            this.formPanelRef = form;
          }
        });
      }

    }
  }

  /**
   * Сохранение данных вкладки
   */
  save(dossierId: string): void {
    this.isErrorFLKSubscribe?.unsubscribe();

    const saveForm = () => {
      if (this.activePartComponent.saveDossierPage) {
        this.activePartComponent.saveDossierPage();
        this.activePartComponent.isSave = true;
      }
    };
    const typePage = this.route.snapshot.children[0].data.typePage;
    if (DossierAccessEnumValue.includes(typePage)) {
      this.checkFLK(dossierId);

      const isErrorFLKSubj = this.dossierService.panelInfo[typePage].isErrorFLK;

      this.isErrorFLKSubscribe = isErrorFLKSubj.pipe(skip(1))
        .subscribe(
          (isErrorFLK: boolean) => {
            if (!isErrorFLK) saveForm();
            this.isErrorFLKSubscribe?.unsubscribe();
          }
        );
    } else saveForm();
  }

  /**
   * Запуск проверки вкладки по ФЛК
   */
  checkFLK(dossierId: string): void {
    if (this.activePartComponent && this.activePartComponent.checkFLKDossierPage) {
      this.activePartComponent.checkFLKDossierPage(dossierId);
    }
  }

  loadingCheckFLK(): boolean {
    if (this.activePartComponent && this.activePartComponent.loadingCheckFLK) {
      return this.activePartComponent.loadingCheckFLK();
    }
    return false;
  }

  /**
   * Отображение кнопки сохранить
   * для Резульатов проверки и разделов с таблицами всегда false
   */
  isShowSaveBtn(): boolean {
    // если вкладка не задана
    if (!this.activePart) {
      return true;
    }
    if (!['svedClient', 'svedTarget', 'dopInfo', 'attributes'].includes(this.activePart)) {
      return false;
    }
    return !this.dossierService.getReadOnly(this.activePart).getValue();
  }

  /**
   * Отображение кнопки проверить
   */
  isShowCheckFLKBtn(): boolean {
    // если вкладка не задана
    if (!this.activePart) {
      return true;
    }
    if (!['svedClient', 'svedTarget', 'dopInfo', 'attributes'].includes(this.activePart)) {
      return false;
    }
    return !this.dossierService.getReadOnly(this.activePart).getValue();
  }

  /**
   * Проверка по факторам риска
   */
  analyzeRisk(): void {
    this.loadingAnalyzeRisk = true;
    this.dossierService.analyzeRisk([{checkObjectId: this.dossierId}])
      .pipe(finalize(() => this.loadingAnalyzeRisk = false))
      .subscribe(data => {
      const info = data[0];
      if (info) {
        this.riskCount = info.totalCount;
        this.existsPrev = info.existsPrev;
        this.riskPanelShow = true;
      }
    });
  }

  /**
   * Завершение редактирование
   */
  finishEdit(): void {
    // Проверка сохранения всех вкладок досье
    if (this.dossierService.checkSaveDataDossier()) {
      // Сммена статуса досье
      this.dossierService.activeStatus(this.dossierId).subscribe(data => {
        this.status = DossierStatusEnum.ACTIVE;
      });
    } else {
      this.alertObjDossier.open(
        this.alertPanelSrv.getErrorMsg('Для завершения редактирования необходимо сохранить данные на всех вкладках')
      );
    }
  }

  /**
   * Открыть окно настройки ФКЛ
   */
  showCheckSetupFLK(): void {
    this.modalService.open(SetupFLKWindowComponent, {size: 'xl', scrollable: true});
  }

  ngOnInit(): void {
    // открываем первую вкладку при переходе на главный комопнент
    if (['create/dossier', 'dossier/:id'].includes(this.route.routeConfig.path) && this.route.firstChild === null) {
      this.router.navigate(['svedClient'], {relativeTo: this.route, replaceUrl: true});
    }
    const statusDossier = this.route.snapshot.data.statusDossier as DossierStatusModel;
    if (statusDossier.partChecked) {
      Object.keys(statusDossier.partChecked).forEach((typePage: DossierAccessEnum) => {
        this.dossierService.setErrorFLK(typePage, !statusDossier.partChecked[typePage]);
      });
    }
    // Получаем статус досье
    this.status = statusDossier.status;
    this.dossierId = this.route.snapshot.params.id;
    this.isEdit = !!this.dossierId;

    if(this.status === DossierStatusEnum.CLOSED){
	      this.isEdit = false;
    }
    this.initReadOnlyDossier();
    this.isHistory = this.dossierService.getIsShowHistory();
    if (this.isHistory) {
      this.versionHistory = this.dossierService.getHistoryVersion();
      this.dossierService.setAllPageReadOnly(true);
    }

    /* проверка, если открыли существующее досье, то проверки запрашиваем */
    if (this.isEdit && this.userService.checkAccess(this.accessEnum.RISKVIEW)) {
      this.dossierService.isNeedSolutionsCheck(this.dossierId).subscribe((value) => {
        this.hasCheckClientResolve = value;
      });
    }
    if (this.isEdit && this.userService.checkAccess(this.accessEnum.TRANSACTIONCHECKLIST)) {
      this.dossierService.isNeedSolutionsTransaction(this.dossierId).subscribe((value) => {
        this.hasCheckTransactionResolve = value;
      });
    }

    this.dossierService.svedClientDataSubject.subscribe((data) => {
      if (data) {
        const dataList = Object.values(data) as ClientInfo[];
        const clientInfo = dataList.length === 1 ? dataList[0] : null;
        if (!clientInfo) {
          throwError('Не удалось получить данные клиента!');
        }
        this.orgName = this.dossierService.getOrgNameByType(clientInfo);
      }
    })
    this.dossierService.updateClientInfo(this.dossierId);
    this.initActivePart();
    this.sSaveButtonSubscribe = this.dossierService.saveButtonIsDisabled.subscribe( (disabled) => {
      this.disabledSaveButton = disabled;
    })
  }

  /**
   * Инициализация активной вкладки
   */
  initActivePart(): void {
    const activeRouteChildren = this.route.children[0];
    if (activeRouteChildren) {
      this.activePart = activeRouteChildren.routeConfig.path;
    }
    this.routerEventsSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activePart = this.route.children[0].routeConfig.path;
        this.dossierService.saveActivePartCode(this.activePart);
      }
    });
    this.dossierService.saveActivePartCode(this.activePart);
  }

  /**
   * Инициализация прав просмотра досье
   */
  initReadOnlyDossier(): void {
    this.isReadOnly = this.status === DossierStatusEnum.CLOSED;
    if(this.isEdit && !this.isReadOnly){
      this.isLoaded = null;
      this.dossierService.setDossierIdStore(this.dossierId)
      this.dossierService.startEdit(this.dossierId).subscribe(response => {
        if(!response?.result) {
          this.isReadOnly = true;
          this.alertPanelComponent.open(
            this.alertPanelSrv.getErrorMsg(response?.message ?? "Ошибка редактирования документа", false)
          );
        }
        this.initReadOnlyPartDossier();
        this.isLoaded = true;
      }, () => {
        this.isLoaded = true;
      });
    }else{
       this.initReadOnlyPartDossier();
    }
  }

  initReadOnlyPartDossier(): void {
    Object.keys(this.dossierService.panelInfo).forEach(typePage => {
      let isReadOnlyPart = this.isReadOnly;
      if(!isReadOnlyPart){
        // Блокируем кнопки в форме создания для всех вкладок кроме первой
        if (!this.isEdit && typePage !== DossierAccessEnum.SVEDCLIENT) {
          isReadOnlyPart = true;
        } else{
          const access = this.userService.getAccess(typePage);
          isReadOnlyPart = access ? access.readOnly : false;
        }
      }
      this.dossierService.setReadOnly(typePage as DossierAccessEnum, isReadOnlyPart);
    });
  }

  /**
   * URL для экспорта досье
   */
  exportDossierUrl(): string {
   return `/api/v1/docs/export/${this.dossierId}`;
  }

  /**
   * Сбрасываем данные при уничтожении компонента
   */
  ngOnDestroy(): void {
    this.dossierService.resetData();
    this.routerEventsSub.unsubscribe();
    if (this.sSaveButtonSubscribe) {this.sSaveButtonSubscribe.unsubscribe()}
  }

  /**
   * Сменить версию всего досье
   */
  changeVersion(event: IVersionCallback): void {
    // устанавливаем версию истории всего досье
    this.dossierService.setIsShowHistory(!event.isActualVersion, event.versionInfo.versionFull);
    const partName = this.route.children[0].routeConfig.path as DossierAccessEnum;
    this.dossierService.setAllPageLoadedData(false);
    if (event.isActualVersion) {
      this.initReadOnlyDossier();
    } else {
      this.dossierService.setAllPageReadOnly(true);
    }
    this.dossierService.accessInitAfterRefresh = true;
    // Перезагружаем текущий компонент
    this.router.navigate([partName], {relativeTo: this.route, skipLocationChange: true});
  }

  navigateToCheckClient(): void {
    this.router.navigate(['checkClient'], {
      relativeTo: this.route,
      replaceUrl: true,
      queryParams: {refresh: new Date().getTime()}
    });
  }

}
