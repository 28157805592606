import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {TableService, UserService} from '@amlCore/services';
import {animate, style, transition, trigger} from '@angular/animations';
import {Unsubscribable} from 'rxjs';
import {AlertPanelComponent, AlertPanelService, AlertService} from "@amlCore/components";
import {OrganizationModel} from "@amlCore/models";

@Component({
  selector: 'app-client-page',
  templateUrl: './client.component.html',
  animations: [
    trigger('fade', [
      // open
      transition('void => *', [
        style({opacity: 0}),
        animate('0.2s', style({
          opacity: 1,
        })),
      ]),
      // hide
      transition('* => void', [
        animate('0.2s', style({
          opacity: 0,
        })),
      ]),
      // -----
    ])
  ],
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, OnDestroy {
  private sharedMenuSubscribe: Unsubscribable;
  private _login = '';
  public isOpenMenuNav = false;
  public toggleClass = "col-lg-12";
  @ViewChild('alertPanel', {static: true}) alertPanel: AlertPanelComponent;
  private orgInfo: OrganizationModel;

  constructor(public tableSrv: TableService,
              private alertPanelSrv: AlertPanelService,
              private alertSrv: AlertService,
              private userService: UserService) {
  }

  ngOnInit(): void {
    /*Скролл основного окна*/
    const mainBlock = document.getElementById('mainblock');
    setTimeout(() => {
      mainBlock.scrollTop = +localStorage.getItem('main-block-scroll');
    }, 1200);
    mainBlock.addEventListener('scroll', () => {
      localStorage.setItem('main-block-scroll', String(mainBlock.scrollTop));
    });

    this._login = this.userService.getAuthenticateUser.login;
    this.sharedMenuSubscribe = this.tableSrv.sharedMenu().getSubscribe().subscribe((isPinnedMenu) => {
      if (typeof isPinnedMenu === "boolean") {
        this.fadeDone();
      }
    });
    this.userService.orgInfo.subscribe((data: OrganizationModel) => {
      this.orgInfo = data;
    });
    this.userService.certMessageError.subscribe((result) => {
      if (result) {
        this.alertSrv[result.type](result.message);
        this.userService.certMessageErrorSubject.next(null);
      }
    })
    this.tableSrv.alertMessage.subscribe(data => {
      if (data) {
        this.alertSrv[data.type](data.message, undefined, undefined, 10 * 1000, true)
      }
    })
  }

  public fadeDone(): void {
    if (this.isVisiblePinnedMenuNav()) {
      this.toggleClass = "col-lg-10";
    } else {
      setTimeout(() => {
        this.toggleClass = "col-lg-12";
      }, 200);
    }
  }

  public isVisiblePinnedMenuNav(): boolean {
    return Boolean(JSON.parse(sessionStorage.getItem(`isPinnedMenuNav-${this._login}`)));
  }

  ngOnDestroy(): void {
    this.sharedMenuSubscribe.unsubscribe();
  }

}
