import {Utils} from '@amlCore/utils';
import {FormGroup} from '@angular/forms';
import {ClientInfo, DossierRequest} from '../../../models/dossier';
import {IDossierSubService} from './IDossierSub.service';
import {DossierService} from '../../../services';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DictionaryService, UserService} from '@amlCore/services';
import {Unsubscribable} from 'rxjs';
import {OnDestroy} from '@angular/core';
import {DossierBaseComponent} from './dossierBase.component';
import {AlertPanelService, ITableCallBack} from '@amlCore/components';
import {ClientTypeEnum, DossierAccessEnum} from '@amlCore/enums';
import {IVersionCallback, VersionComponent} from './components/version';
import {IDossierPage} from '../../../interfaces';
import {PartItemArray} from '../../../models';
import {ConfirmComponent} from "@amlCore/modals";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileSaverService} from "ngx-filesaver";
import {DossierStatusEnum} from './dossierStatusEnum';

/**
 * Базовый класс для всех вкладок в досье
 */
// TODO: Add Angular decorator.
export abstract class DossierBasePageComponent extends DossierBaseComponent implements OnDestroy, IDossierPage {
  // Скрыли механизм истории для вкладок
  versionSelect: VersionComponent;
  clientType: ClientTypeEnum;
  topPage = Utils.scrollTopSmooth;
  goBack = Utils.goBack;
  panelForm: FormGroup;
  partId = '';
  showPage = true;
  showPageFromValid = true;
  isReadOnly = false;
  dossierId;
  typePage: DossierAccessEnum;
  isEdit: boolean;
  modelPage: any;
  // Обновлять ли данные вкладки после сохранения
  private _isRefreshBeforeSave = false;
  private isReadOnlySubscription: Unsubscribable;
  private isSaveDataSubscription: Unsubscribable;
  private navigationSubscription: Unsubscribable;
  private clientDataSubscription: Unsubscribable;
  abstract isTableTab: boolean;
  private _subEntitysMap = new Map([
    [ClientTypeEnum.LEGAL_ENTITY, 'svedUL'],
    [ClientTypeEnum.INDIVIDUAL_PERSON, 'svedFL'],
    [ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR, 'svedIP'],
    [ClientTypeEnum.PRIVATE_PRACTITIONER, 'svedFLCP'],
    [ClientTypeEnum.FOREIGN_STRUCTURE, 'svedInbUL']
  ]);

  protected constructor(protected  dossierService: DossierService,
                        protected  route: ActivatedRoute,
                        protected  dictSrv: DictionaryService,
                        protected  router: Router,
                        protected userService: UserService,
                        protected alertPanelSrv: AlertPanelService,
                        protected readonly modalService: NgbModal,
                        protected fSaver: FileSaverService) {
    super(dossierService, dictSrv, alertPanelSrv);
    // FIXME это надо потом получать с сервера для каждого раздела
    this._getClientType();
  }

  /**
   * Инициализируем вкладку и получаем данные для вкладки
   * @param changeVersion - признак смены версии
   * @return данные для заполнения вкладки
   */
  initPage(changeVersion?: boolean): any | Array<any> {
    this.dossierId = this.route.snapshot.parent.params.id;
    this.isEdit = !!this.dossierId;
    const snapshotData = this.route.snapshot.data;
    if (snapshotData && snapshotData.typePage) {
      this.typePage = snapshotData.typePage;
      this._isRefreshBeforeSave = snapshotData.refreshBeforeSave
      this.initErrorFLK(snapshotData.errorFLK);
      if (snapshotData.pageData && !changeVersion) {
        const pageDataMap = snapshotData.pageData;
        this.modelPage = this.initModel(pageDataMap);
      }

      this.initFormControl();
      // Блокируем доступ по прямой ссылке для формы создания - только просмотр кроме первой вкладки
      // Если не актуальная(последняя) версия
      // TODO: если форма просмотра -  всегда true
      if ((!this.isEdit && this.typePage !== DossierAccessEnum.SVEDCLIENT) || this.dossierService.getShowHistory(this.typePage)) {
        this.dossierService.setReadOnly(this.typePage, true);
      }
    } else {
      console.log("Необходимо указать тип вкладки");
    }
    this.isReadOnly = this.dossierService.getReadOnly(this.typePage).getValue();
    this.isReadOnlySubscription = this.dossierService.getReadOnly(this.typePage).subscribe(value => {
       this.isReadOnly = value;
       this.initReadOnly();
    });
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // Вызываем после перезагрузки компонента без смены роутина
      if (e instanceof NavigationEnd) {
        // Ручное управление проверки разрешений
        if (this.dossierService.accessInitAfterRefresh) {
          this.dossierService.accessInitAfterRefresh = false;
          this.initAfterRefresh();
        }
      }
    });
    if (this.modelPage) {
      this.showPageFromValid = false;
      setTimeout(() => {
        this.panelForm.patchValue(this.modelPage);
        if (this.isTableTab) {
          this.initErrorTableTab();
        }

        this.initReadOnly();
        // установка состояния полей disable/enable, и ТОЛЬКО ПОТОМ СРЕЗ ЭТАЛОННОГО СОСТОЯНИЯ ФОРМЫ ДЛЯ КЕША.
        this.setStateFormControls();

        //Сохраняем в кэш только актуальные данные, а не данные старых версий, чтобы в хедере всегда отображалось актуальное наименование
        if(!this.dossierService.getIsShowHistory()){
          this.initFormDataCache();
        }

        // TODO: Автоустановка ошибок по ФЛК
        // this.initErrorFLKSimpleField();
        // Задержка в 1 млсек обеспечивает выполнение этого setTimeout последним
        // В подкомпонентах тоже выполняется setTimeout для обеспечения корректного проставления переменных
      }, 1);
    } else {
      this.panelForm = this.getService().getForm();
      this.showPageFromValid = false;
      this.initReadOnly();
    }
    return this.modelPage;
  }

  /**
   * установка состояния полей disable/enable
  */
  setStateFormControls(): void {
    const rootEntity = `${this.route.snapshot.data.typePage}`;
    const controlClientType = this.panelForm.get(`${rootEntity}.clientType`);
    if (controlClientType) {
      const clientTypeCode = controlClientType.value;
      const subEntity = this._subEntitysMap.get(clientTypeCode);
      // установка состояние полей disable/enable перед тем как сделать срез формы для кеша.
      this.setActiveClientInfo(`${rootEntity}.${subEntity}`);
      this.initPrv();
    }
  }

  /**
   * Установить активную часть ClientInfo
   * @param pathEntity - путь до подсущности формы.
   */
  setActiveClientInfo(pathEntity: string): void {
      if (!this.isReadOnly) {
        const rootEntity = `${this.route.snapshot.data.typePage}`;
        const clientTypeSubEntitys = ['svedUL', 'svedFL', 'svedIP', 'svedFLCP', 'svedInbUL'];
        clientTypeSubEntitys.forEach((subEntity: string) => {
          this.panelForm.get(`${rootEntity}.${subEntity}`).disable();
        });
        const subEntity = this.panelForm.get(pathEntity);

        if (subEntity !== undefined) {
          subEntity.enable();
        }
      }
  }

  /**
   * установка состояние полей disable/enable в зависимости от значения prv
  */
  initPrv(): void {
    const rootEntity = `${this.route.snapshot.data.typePage}`;
    const clientType = this.panelForm.get(`${rootEntity}.clientType`).value;
    const subEntity = `${this._subEntitysMap.get(clientType)}`;
    const controlAddress = this.panelForm.get(`${rootEntity}.${subEntity}.publicInfo.address`);
    const controlPrv = this.panelForm.get(`${rootEntity}.${subEntity}.publicInfo`);

    if (controlPrv && controlAddress) {
      const prvValue: boolean = this.panelForm.get(`${rootEntity}.${subEntity}.publicInfo`).get('prv').value;
      prvValue ?  controlAddress.enable() : controlAddress.disable();
    }
  }

  initReadOnly(): void {
    if (this.isReadOnly) {
      // Блокируем с задержкой и без обновления
      // Иначе либо не успеют отработать внутренние setTimeout (нужна задержка),
      // либо обработчики разблокируют группы (emitEvent: false)
      setTimeout(() => {
        this.panelForm.disable({emitEvent: false});
        this.showPageFromValid = true;
      }, 1000);
    } else {
      this.showPageFromValid = true;
    }
  }

  private _getClientType(): void {
    this.clientDataSubscription = this.dossierService.getClientType().subscribe((clientType) => {
      this.clientType = clientType;
    });
  }

  initFormDataCache(): void {
    if (!this.dossierService.getFormCacheSave(this.typePage)) {
      this.dossierService.setFormCacheSave(this.typePage, true);
      // Сохраняем значение формы при открытии для признака именения на форме
      this.dossierService.setFormDataCache(this.typePage, this.panelForm.value);
    }
  }

  initAfterRefresh(): void {
    this.changeModel(this.route.snapshot.data.pageData);
  }

  /**
   * Инициализация модели
   * Преобразуем в нужный формат (для некоторых вкладок своя обработка)
   * @param model - данные сервера
   */
  initModel(model): any {
    let newModel;
    this.partId = null;
    newModel = model ? this.pushPartIdInModel(model) : [];
    const formData = {};
    formData[this.typePage] = newModel;
    return formData;
  }

  pushPartIdInModel(model: any): any {
    const newModel = [];
    Object.keys(model).forEach(partId => {
      const item = model[partId];
      item.$partId = partId; // id части, сохраняем для удаления элемента
      newModel.push(item);
    });
    Utils.deleteEmptyObj(newModel);
    return newModel;
  }

  /**
   * Сменить модель (смена версии,перезагрузка данных и др)
   * перестраиваем модель и инициализурем новые контролы
   * @param data - данные вкладки
   */
  changeModel(data): void {
    this.dossierService.setLoadedData(this.typePage, false);
    data = this.initModel(data);
    this.initFormControl();
    this.showPage = false;
    this.showPageFromValid = false;
    if (data && data[this.typePage]) {
      setTimeout(() => {
        this.showPage = true;
        if (!this.isReadOnly) {
          this.panelForm.enable();
        }
        this.panelForm.patchValue(data);
        if (this.isTableTab) {
          this.initErrorTableTab();
        }
        this.initReadOnly();
      }, 1);
    } else {
      this.showPage = true;
      this.initReadOnly();
    }
  }

  /**
   * Изменить версию вкладки
   * @param event - ответ компонента по версиям
   * model - данные версии
   * isActualVersion - актуальная ли версия
   */
  changeVersion(event: IVersionCallback): void {
    this.dossierService.setShowHistory(this.typePage, !event.isActualVersion);
    this.dossierService.setReadOnly(this.typePage, !event.isActualVersion);
    this.dossierService.setVersion(this.typePage, event.versionInfo.versionSummary);
    if (event.isActualVersion) {
      this.reloadData();
    } else {
      this.changeModel(event.model);
    }
  }

  /**
   * Перезагрузить данные вкладки
   */
  private reloadData(): void {
    this.dossierService.getDossierByType({
      id: this.dossierId,
      partName: this.typePage,
      model: null
    } as DossierRequest).subscribe(data => {
      this.changeModel(data);
    });
  }

  f(field: string) {
    return this.panelForm.get(field);
  }

  fGroup(field: string): FormGroup {
    return this.panelForm.get(field) as FormGroup;
  }

  /**
   * Инициализация формы
   */
  initFormControl(): void {
    this.panelForm = this.getService().getForm();
    // Ставим данные если пришли
    // Проставляем контролы элементам массива при первом открытии
    if (!this.dossierService.getLoadedData(this.typePage)) {
      this.panelForm = this.getService().getForm(true);
    }
    this.dossierService.setLoadedData(this.typePage, true);
    this.isSaveDataSubscription = this.panelForm.valueChanges.subscribe(data => {
      // вызываем только при изменения поля руками для вкладок с полями
      if (!this.panelForm.pristine && !this.isTableTab) {
        this.dossierService.setSaveData(this.typePage, this.dossierService.checkFormDataCache(this.typePage, data));
      }
    });
  }

  abstract getService(): IDossierSubService;

  saveDossierPage(dataTable?): void {
    if (this.panelForm.invalid) {
      this.submitted = true;
      this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg('Необходимо заполнить обязательные поля'));
      return;
    }
    const saveInfo = Utils.clone(this.panelForm.value[this.typePage]);
    Utils.deleteEmptyObj(saveInfo);
    // Удаляем поля $partId, $checkedFlk
    Utils.removeKeysFromObj(saveInfo, ['$partId', '$checkedFlk']);
    const param = {} as DossierRequest;
    param.partName = this.typePage;
    param.model = saveInfo;
    if (this.dossierId) {
      param.id = this.dossierId;
    } else {
      if (this.typePage !== DossierAccessEnum.SVEDCLIENT) {
        this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg('Новую анкету надо создавать со вкладки "Сведения о клиенте"'));
        return;
      }
    }

    if (this.typePage === DossierAccessEnum.ATTRIBUTES && saveInfo.dateEnd){
        const modal = this.modalService.open(ConfirmComponent);
        modal.componentInstance.text = 'Досье клиента будет закрыто. Повторно открыть досье будет невозможно!';
        modal.componentInstance.showWarningIcon = true;
        modal.result.then(data => {
          this.doSaveDossierPage(param);
       }, cancel => {});
    } else {
       this.doSaveDossierPage(param);
    }

  }

  doSaveDossierPage(param): void {
      this.dossierService.saveButtonIsDisabled.emit(true)
	    this.dossierService.saveDossierByType(param).subscribe(data => {
      if (this.typePage === DossierAccessEnum.DOSSIERACCESS) {
        this.dossierService.partIdCache.next(Array.isArray(data) && data.length === 1 ? data[0].id : null);
      }

      if(data && data.doubleId){
        this.openUniqueCheckModalDialog(param);
        return;
      }

      if (this._isRefreshBeforeSave) {
        this.reloadData();
      }

      if (data && data.id) {
        if (!this.isEdit) {
          this.router.navigate(['dossier', data.id], {replaceUrl: true});
        } else {

	      if (this.typePage === DossierAccessEnum.ATTRIBUTES &&
	          data.status === DossierStatusEnum.CLOSED) {
            this.router.navigate([`dossier/${data.id}/attributes`])
             .then(() => {
              window.location.reload();
            });

	      }else if (!this.isTableTab) {
            this.dossierService.setErrorFLK(this.typePage, !data.partChecked);
          }
          // TODO возможно это уже не нужно
          // if (this.typePage !== DossierAccessEnum.SVEDCLIENT) {
          //   this.reloadData();
          // }
        }
      }
      // Перезагружаем список версий
      if (this.versionSelect) {
        this.versionSelect.getVersionList(true);
      }
      // Сбрасываем флаги формы
      this.panelForm.markAsPristine({onlySelf: false});
      this.panelForm.updateValueAndValidity({onlySelf: false});
      if (!this.isTableTab) {
        this.dossierService.setSaveData(this.typePage, true);
        // Сохраняем значение формы для признака именения на форме
        this.dossierService.setFormDataCache(this.typePage, this.panelForm.value);
      }
      this.alertPanelComponent.open(this.alertPanelSrv.getSuccessMsg('Данные по вкладке сохранены'));
    }, data =>{
      if(data && data.doubleId){
        this.openUniqueCheckModalDialog(param);
      }
    }, () =>  { this.dossierService.saveButtonIsDisabled.emit(false)});
  }

  /**
   * Обновление вкладок досье
   */
  setReadOnlyDossier(): void {
    Object.keys(this.dossierService.panelInfo).forEach(typePage => {
      let isReadOnly = true;
      this.dossierService.setReadOnly(typePage as DossierAccessEnum, isReadOnly);
    });
  }


  /**
   * Метод открытия модального диалога, если досье не прошло прверку на уникальность
   * @param param DossierRequest данные досье, которое пытались сохранить
   * @returns void
   */
  public openUniqueCheckModalDialog(param: DossierRequest) {
    const modal = this.modalService.open(ConfirmComponent);
    modal.componentInstance.title = "Внимание";
    modal.componentInstance.text = "Клиент уже зарегистрирован в клиентской базе. Сохранение невозможно. Совпадающие значения будут выведены в отчет.";
    modal.componentInstance.okBtn = "Ок";
    modal.componentInstance.cancelBtn = "Отмена";
    modal.result.then(() => {
       this.dossierService.downloadUniqueReportCsv(param).subscribe(data => {
         if (data.type === 4) {
           this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
         }
       });
    },(error) => {
      console.warn(error);
    });
  }

  /**
   * Инициализация ошибок по ФЛК для табличных вкладок
   * получение ошибок по конкретным элементам списка
   */
  private initErrorTableTab(): void {
    if (this.typePage === DossierAccessEnum.DOSSIERACCESS) { return; }
    this.dossierService.checkPartFLK({
      id: this.dossierId,
      partName: this.typePage,
      partId: this.partId,
    } as DossierRequest).subscribe((response: { [key: string]: boolean })  => {
      if (response) {
        Object.keys(response).forEach(key => this.setErrorTableTab(key, response[key]));
        // Проверка наличия ошибок по элементам - если есть проставлем признак для вкладки
        const allTrue = Object.values(response).every(result => result === true);
        this.dossierService.setErrorFLK(this.typePage, !allTrue);
      }
    });
  }

  /**
   * Установка признаков ошибки по ФЛК для списков
   * @param key - id части в списке или row
   * @param result - результат ФЛК
   */
  setErrorTableTab(key, result){
    const form = this.f(this.typePage);
    const values = form.value as PartItemArray[];
    if (values) {
      const index = values.findIndex(item => item.$partId === key);
      if (index >= 0) {
        values[index].$checkedFlk = !result;
      }
      form.patchValue(values);
    }
  }

  /**
   * Удалить элемент таблицы
   */
  deleteTableItem(event: ITableCallBack): void {
    const data = event.deleteData;
    const paramDelete = {} as DossierRequest;
    paramDelete.partName = this.typePage;
    paramDelete.id = this.dossierId;
    paramDelete.model = [data['$partId']];
    this.dossierService.deletePartDossierByType(paramDelete).subscribe(() => {
      this.saveDossierPage();
    });
  }

  /**
   * Сохранить элемент таблицы
   */
  saveTableItem(event: ITableCallBack): void {
    if (event.editIndex != null) {
      const item = this.panelForm.get(this.typePage).value[event.editIndex];
      this.saveDossierPage([item]);
    }
  }

  ngOnDestroy(): void {
    this.isReadOnlySubscription.unsubscribe();
    this.isSaveDataSubscription.unsubscribe();
    this.navigationSubscription.unsubscribe();
    if(this.clientDataSubscription)
      this.clientDataSubscription.unsubscribe();
    this.destroy();
  }

  /**
   * Установка ссылки на внешний объект истории версии
   * @param versionComponent - компонент истории версии
   */
  setVersionComponentDossierPage(versionComponent: VersionComponent): void {
    this.versionSelect = versionComponent;
  }

  /**
   * Проверка вкладки по ФЛК
   */
  checkFLKDossierPage(dossierId: string): void {
    const panelForm = this.panelForm.controls[this.typePage] as FormGroup;
    this.checkFLKBase(panelForm, this.typePage, dossierId);
  }

  /**
   * Получения списка ошибок по части досье
   * @param item - часть
   */
  checkPartFLK(item: PartItemArray): void {
    this.dossierService.checkPartFLK({
      id: this.dossierId,
      partName: this.typePage,
      partId: item.$partId
    } as DossierRequest).subscribe(response => {
      // TODO: Временная реализация для проверки работы метода
      console.log(response);
    });
  }
  abstract destroy();
}
