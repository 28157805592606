import {DictType} from "./dictType.model";
import {DossierBool, DossierM50, DossierN3, DossierN5, DossierT2000, DossierT250, DossierT4000} from "./base.model";
import {NetworkInfo} from "./networkInfo.model";

export class DopInfoType {
  selfEmployed?: DossierBool = false;
  category?: DictType = undefined;
  sho?: DossierBool = '';
  goz?: DossierBool = false;
  orc?: DossierBool = false;
  leastCapital?: DossierM50 = '';
  currencyReg?: DictType = undefined;
  capital?: DossierM50 = '';
  currencyPaid?: DictType = undefined;
  share: DossierN3 = '';
  asset: DossierT2000 = '';
  estate?: DictType[] = undefined;
  otherSource?: DossierT4000 = '';
  staffCount?: DossierN5 = '';
  wageFund?: DossierM50 = '';
  accountsDep?: DictType = undefined;
  activity?: DictType = undefined;
  income?: DictType = undefined;
  reputation?: DictType = undefined;
  network: Array<NetworkInfo> = undefined;
}
