export enum typePageEnum {
    PASSPORT_INFO = 'passportInfo', // "Перечни/Недействительные паспорта"
    RISKFACTOR_INFO = 'riskfactorInfo', // "Перечни/Список факторов",
    FROMU_INFO_LIST = 'fromuInfoList', // "Перечни/ФРОМУ"
    CONSOLIDATED_INFO_LIST = 'consolidatedInfoList', // "Перечни/Сводный перечень Совета Безопасности ООН"
    MVK_INFO_LIST = 'mvkInfoList', // "Перечни/МВК"
    BOUNCE_REGISTRIES_ACCEPTED_LIST = 'bounceRegisterAcceptedList', // "Перечни/Реестр отказов"
    INTERNAL_LIST = 'internalList', // "Внутренний список организаций",
    DOSSIER = 'dossier', // Досье клинетов
    DOSSIER_CHECK = 'dossierCheck', // результаты проверок
    NOTICE = 'notice',   // "Оповещения"
    CLEINTS_TRANSACTIONS = 'clientsTransactions', // "Операции клиентов"
    CHECK_TRANSACTIONS = 'checkTransactions', // "Результаты анализа операций"
    CHECK_TRANSACTIONS1 = 'checkTransactions-new', // "1 Результаты анализа операций"
  }
