import {PublicInfoType} from "./publicInfoType.model";
import {ContactType} from "./contactType.model";
import {DocumentInfoType} from "./documentInfoType.model";
import {PersonalDataType} from "./personalDataType.model";
import {FIOType} from "./fioType.model";
import {AddressInfoType} from "./addressInfoType.model";

export class SvedFLType {
  fio: FIOType = new FIOType();
  personalData: PersonalDataType = new PersonalDataType();
  documentInfo: Array<DocumentInfoType> = undefined;
  addressInfo: AddressInfoType = new AddressInfoType();
  contactInfo: ContactType = new ContactType();
  publicInfo?: PublicInfoType = new PublicInfoType();
}
